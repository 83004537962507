<template>
  <section id="hero">
    <div class="hero-content">
      <h1
        data-content="Cnai Accelerator Cambodia"
        v-html="'Cnai Accelerator Cambodia'"
      ></h1>
      <p>
        Our mission is to nurture and encourage founders to grow impactful,
        gender inclusive startups and SMEs in Cambodia.
      </p>
      <img
        class="hero-character-1"
        src="../assets/hero_character_1.png"
        alt="hero image"
      />
    </div>
    <div class="hero-cover-image"></div>
  </section>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style lang="scss" scoped>
@import "@/scss/definations.scss";
@import "@/scss/hero.scss";
</style>
