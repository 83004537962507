import { createStore } from "vuex";

export default createStore({
  state: {
    isMenuOpen: false,
  },
  mutations: {
    toggleMenuOpen(state) {
      state.isMenuOpen = !state.isMenuOpen;
    },
  },
  actions: {
    toggleMenuOpen({ commit }) {
      commit("toggleMenuOpen");
    },
  },
  modules: {},
});
