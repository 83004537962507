import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const PageName = "Cnai Accelerator Cambodia";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: `Home Page - ${PageName}`,
    },
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   meta: {
  //     title: `About - ${PageName}`,
  //   },
  //   component: () => import("../views/About.vue"),
  // },
  // {
  //   path: "/past-cohorts",
  //   name: "Past Cohorts",
  //   meta: {
  //     title: `Past Cohorts - ${PageName}`,
  //   },
  //   component: () => import("../views/PastCohorts.vue"),
  // },
  {
    path: "/application-n-program-process",
    name: "Application & Program Process",
    meta: {
      title: `Application & Program Process - ${PageName}`,
    },
    component: () => import("../views/ProgramProcess.vue"),
  },
  {
    path: "/cnai-supporters",
    name: "Cnai Supporters",
    meta: {
      title: `Cnai Supporters - ${PageName}`,
    },
    component: () => import("../views/CnaiSupporters.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
