<template>
  <div class="container">
    <h2 class="title mb-3" :data-content="title">
      {{ title }}
    </h2>
    <div class="rocket-area">
      <div class="rocket-button" @click="lunchTheRocket()">
        <div
          class="rocket"
          :class="{
            lunch,
          }"
        >
          <img
            class="rocket-body"
            src="../assets/rocket/rocket.png"
            alt="started today image"
          />
          <img
            class="rocket-burst"
            src="../assets/rocket/burst.png"
            alt="rocker burst"
          />
        </div>
      </div>

      <img
        class="rocket-text"
        src="../assets/rocket/rocket_text.png"
        alt="rocket text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "RocketSection",
  data() {
    return {
      lunch: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    lunchTheRocket() {
      this.lunch = true;
      setTimeout(() => {
        console.log(`${this.$route.name} > RocketClick `);
        const pageName =
          this.$route.name === "Application & Program Process"
            ? "Application"
            : this.$route.name;
        this.$gtag.event(`${pageName} > RocketClick `, {
          event_category: "Rocket Clicked",
          event_label: `${pageName} > RocketClick `,
          value: 1,
        });
        location.assign("https://forms.gle/YRgfpQxRmNXSucPY6");
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  img {
    width: 20rem;
    margin-bottom: 0rem;
  }

  .title{
    width: 100%;
  }
  .rocket-area {
    width: 100vw;
    height: 20rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: red; */
    .rocket-button {
      position: absolute;
      bottom: 0;
      .rocket {
        position: relative;
        cursor: pointer;
        width: 100%;
        .rocket-body {
          position: relative;
          z-index: 150;
          width: 10rem;
        }
        .rocket-burst {
          position: absolute;
          z-index: 130;
          bottom: -15%;
          left: 15%;
          /* padding-top: 10rem; */
          width: 7rem;
        }

        &.lunch {
          animation-name: lunch-rocket;
          animation-duration: 2s;
          animation-timing-function: ease;
        }

        &:not(.lunch) {
          animation-name: float-rocket;
          animation-duration: 6s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
        }
      }
    }

    .rocket-text {
      position: absolute;
      margin-left: 20rem;
      width: 10rem;

      @media (max-width: 900px) {
        top: 5rem;
        margin-left: 17rem;
        width: 5rem;
      }
    }
  }
  @media (max-width: 800px) {
    img {
      width: 20rem;
      margin-bottom: 2rem;
    }
  }
}

@keyframes lunch-rocket {
  from {
    margin-top: 0rem;
  }
  to {
    margin-top: -70rem;
  }
}
@keyframes float-rocket {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-25px);
  }
  100% {
    transform: translatey(0px);
  }
}
</style>
