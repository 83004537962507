<template>
  <div class="video-container">
    <video
      ref="videoPlayer"
      class="video-js vjs-big-play-centered"
      :poster="poster"
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";
export default {
  name: "VideComponent",
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  data() {
    return {
      player: null,
      poster:
        "https://san-thit.s3.ap-southeast-1.amazonaws.com/Microsoft_Teams_image_78f9a14b8e.png",
      options: {
        autoplay: true,
        controls: true,
        width: "640",
        sources: [
          {
            // src: "https://san-thit.s3.ap-southeast-1.amazonaws.com/Cnai_Accelerator_Promotional_Video_KH_269dd82bd1.mp4",
            src: "https://san-thit.s3.ap-southeast-1.amazonaws.com/Cnai+Animation+(final).mp4",
            type: "video/mp4",
          },
        ],
      },
    };
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750 && this.windowWidth > 450) {
        this.options.width = 450;
        return;
      } else if (this.windowWidth <= 450) {
        this.options.width = 350;
        return;
      } else {
        this.options.width = 640;
        return;
      }
    },
  },
  mounted() {
    try {
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        this.player.log("onPlayerReady", this);
      });
    } catch (error) {
      console.log(error);
    }
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  border-radius: 1rem;
  overflow: hidden;
}
</style>
