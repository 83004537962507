<template>
  <transition name="feedback">
    <div
      class="feedback"
      :class="{
        active: feedback.show,
      }"
      v-show="feedback.show"
    >
      <p>
        {{ feedback.detail }}
      </p>
      <img
        :src="getImageSrc(feedback.img)"
        :alt="`image of ${feedback.name}`"
      />
      <h5 v-for="(person, index) in persons" :key="index">{{ person }}</h5>
      <h5>{{ feedback.position }} - {{ feedback.company }}</h5>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    feedback: {
      type: [Object, null],
      default: null,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    persons() {
      return this.feedback.name.split(",");
    },
  },
  methods: {
    getImageSrc(image) {
      if (image.startsWith("http")) {
        return image;
      }
      return require(`@/assets/feedbacks/${image}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/definations.scss";

.feedback {
  // height: 15rem;

  img {
    // width: 5rem;
    // border-radius: 50%;
    width: 15rem;
    margin-bottom: 0;
  }
  p {
    width: 100%;
    font-size: 0.9rem;
    font-weight: lighter !important;
  }
  h5 {
    line-height: 1.5rem;
    font-weight: bolder;
    font-size: 1rem;
  }
}

.feedback-enter-active {
  transition-property: opacity;
  transition: opacity 0.5s ease;
  /* transition-delay: 0.7s !important; */
}
.feedback-leave-active {
  transition-property: opacity;
  transition: opacity 0.5s ease;
}

.feedback-enter-to {
  opacity: 1;
}

.feedback-enter-from {
  opacity: 0;
}

.feedback-leave-from {
  opacity: 1;
}

.feedback-leave-to {
  opacity: 0;
}
</style>
