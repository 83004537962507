<template>
  <section
    class="container"
    :class="{
      'primary-color': color == 'primary',
      'secondary-color': color == 'secondary',
      'white-color': color == 'white',
    }"
  >
    <div
      class="content"
      :class="[{ 'full-width': fullWidth }, { 'top-content': top }]"
    >
      <slot />
    </div>

    <div
      class="curve-area"
      :class="{
        primary: color == 'primary',
        secondary: color == 'secondary',
        white: color == 'white',
      }"
    ></div>
  </section>
</template>

<script>
export default {
  name: "CurveSection",
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    top: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/definations.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $primary-color;
  @include curve-section-margin-top();

  &.secondary-color {
    background: $secondary-color;
    color: $white-color;
  }

  &.white-color {
    background: $white-color;
    color: $dark-color;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 101;
    width: 60%;
    margin-top: calc(10rem + 2rem);
    &.full-width {
      width: 100%;
    }

    &.top-content {
      margin-top: calc(10rem + 0rem);
    }
  }

  img {
    /* content: url("../assets/yellow_curve.svg"); */
    position: absolute;
    top: 99.8%;
    left: -10%;
    width: 120% !important;
    height: auto;
    z-index: 100;
  }

  .curve-area {
    z-index: 100;
    width: 100%;
    background: transparent;
    @include curve-bottom();

    &.secondary {
      background-color: $secondary-color;
    }
    &.primary {
      background-color: $primary-color;
    }
    &.white {
      background-color: $white-color;
    }
  }
}
</style>
